import { useState, useEffect, useRef, useMemo } from 'react';
import { ContentCopy } from '@mui/icons-material';
import { Alert, Box, IconButton, Button, Grid, Typography, debounce, styled } from '@mui/material';
import StatusCard from '../status-card';
import { useParams } from 'react-router-dom';
import { constructorStatuses } from './statuses';
import {
	ListDetails,
	Response,
	FilesList,
	DeliveriesList,
	Document,
	QuoteStatus,
	Delivery,
	EventStates,
	BusinessName,
} from '../../types';
import File from '../file';
import { useIntl } from 'react-intl';
import { Refresh, ThumbDownOffAlt, ThumbUpAlt, Upload } from '@mui/icons-material';
import DataCard from '../data-card';
import Seller from '../seller';
import Card from '../card';
import DataList from '../data-list';
import EmptySupportedTypography from '../empty-supported-typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableCardTreeView from '../table-card-tree-view';
import { relatedDeliveries, relatedFiles, relatedDeliveryFiles } from './columns';
import CancellationDialog from './dialogs/cancellation-dialog';
import FileUploadDialog from './dialogs/file-upload-dialog';
import ReQuotationDialog from './dialogs/re-quotation-dialog';
import ConfirmationDialog from './dialogs/confirmation-dialog';
import ConfirmationOnlyDialog from './dialogs/confirmation-only-dialog';
import ConfirmationMultiDialog from './dialogs/confirmation-multi-dialog';
import ListComments from './dialogs/list-comments';
import client from '../../clients/client';
import FullScreenLoading from '../full-screen-loading';
import RejectionDialog from './dialogs/rejection-dialog';
import { useMobile } from '../../hooks/use-mobile';
import FileViewer from '../file-viewer';
import Error from '../error';
import PageContainer from '../page-container';
import DeliveryAddress from '../delivery-address';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import toast from 'react-hot-toast';
import PurchaseOrderUploadDialog from './dialogs/purchase-order-upload-dialog';

const STATUSES_WITH_DELIVERY_INFO = [QuoteStatus.COMPRA_FINALIZADA, QuoteStatus.EN_PROCESO_DE_ENTREGA];

const StyledAlert2 = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: '#D8DCED',
	borderRadius: '10px',
	color: '#222',
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: 'transparent',
	padding: '10px',
	borderRadius: '10px',
	color: theme.palette.text.secondary,
	margin: '10px 0',
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
	width: '150px',
}));

const Details = () => {
	const { listId, orderId } = useParams();
	const user = useRecoilValue(userState);

	const intl = useIntl();
	const mobile = useMobile();

	const deliveriesCardRef = useRef<HTMLDivElement>(null);

	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [purchaseOrderUploadDialogOpen, setPurchaseOrderUploadDialogOpen] = useState(false);
	const [reQuotationDialogOpen, setReQuotationDialogOpen] = useState(false);
	const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationOnlyDialogOpen, setConfirmationOnlyDialogOpen] = useState(false);
	const [multiConfirmationDialogOpen, setMultiConfirmationDialogOpen] = useState(false);
	const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
	const [listDetailsResponse, setListDetailsResponse] = useState<Response<ListDetails>>();
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [deliveriesResponse, setDeliveriesResponse] = useState<Response<DeliveriesList>>();
	const [allDeliveries, setAllDeliveries] = useState<Delivery[]>([]);
	const [estimateDownloading, setEstimateDownloading] = useState(false);
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [estimateForcedState, setEstimateForcedState] = useState<any>({ open: false, nameData: null, fileData: null });
	const [companies, setCompanies] = useState<BusinessName[]>([]);

	const [openListCommentsDialog, setOpenListCommentsDialog] = useState(false);
	const [unreadMessages, setUnreadMessages] = useState<number | undefined>();

	const fetchData = debounce(() => {
		setListDetailsResponse(undefined);
		setEstimateForcedState({ open: false, nameData: null, fileData: null });

		client
			.get({ listId, orderId, user })
			.then((data) => {
				setListDetailsResponse({ data });
				fetchListDocuments();
				fetchListDeliveries();
				setUnreadMessages(data?.unread_messages);
				fetchCompanies();
			})
			.catch(() => setListDetailsResponse({ error: true }));
	}, 250);

	const fetchCompanies = debounce(async () => {
		client.getConstructorBusinessNames({ token: user.token })
			.then((response) => {
				setCompanies(response.businessNames);
			})
			.catch((error) =>{
				console.error(error);
				setCompanies([]);
			});
	}, 250);

	const fetchListDeliveries = debounce(() => {
		setDeliveriesResponse(undefined);

		client
			.getListDeliveries({ orderId, user })
			.then((data) => {
				setDeliveriesResponse({ data });
				const delivs = data?.map((delivery: Delivery) => delivery);
				setAllDeliveries(delivs ?? []);
			})
			.catch(() => setDeliveriesResponse({ error: true }));
	}, 250);

	const fetchListDocuments = debounce(() => {
		setFilesResponse(undefined);

		client
			.getListDocuments({ listId, orderId, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	useEffect(() => {
		fetchData();
		setEstimateForcedState({ open: false, nameData: null, fileData: null });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showButtonUploadPurchaseOrder = useMemo(() => {
		const allDocumentsHasPurchaseOrder = allDocuments.some((doc) => doc.file_type_id === 7);
		const documentationStatus = listDetailsResponse?.data && listDetailsResponse.data.quotation.documentation_status;
		return !!filesResponse && !allDocumentsHasPurchaseOrder && documentationStatus === 'Aguardando OC';
	}, [allDocuments, filesResponse, listDetailsResponse?.data]);
	
	if (!listDetailsResponse) {
		return <FullScreenLoading />;
	}

	const { data, error } = listDetailsResponse;
	if (error || !data) {
		return <Error />;
	}

	const { quotation, metadata, files, event_state } = data;

	let allowedFileTypes = [
		{ id: 3, name: 'Cronograma de entrega' },
		{ id: 4, name: 'Presupuesto de referencia' },
		{ id: 22, name: 'Cronograma de pago' },
	];
	if (metadata.can_upload_oc && (orderId || metadata.order_id)) {
		allowedFileTypes = [...allowedFileTypes, { id: 7, name: 'Orden de compra' }];
	}
	if (metadata.can_upload_cp) {
		allowedFileTypes = [
			...allowedFileTypes,
			{ id: 9, name: 'Comprobante de pago' },
			{ id: 10, name: 'Comprobante de retenciones' },
			{ id: 11, name: 'Órden de pago' },
		];
	}

	const status = quotation?.status;

	const dataEntries = [];

	if (STATUSES_WITH_DELIVERY_INFO.includes(status as QuoteStatus)) {
		dataEntries.push({
			key: 'Estado de la entrega',
			value: <EmptySupportedTypography>{quotation.delivery_status}</EmptySupportedTypography>,
			action: {
				displayName: intl.formatMessage({ id: 'common.view' }),
				onClick: () => deliveriesCardRef.current?.scrollIntoView(),
			},
		});
	}

	if (quotation.documentation_status) {
		dataEntries.push({
			key: 'Estado de la Documentación',
			value: <Typography>{quotation.documentation_status}</Typography>,
		});
	}

	if (quotation.created_by_name && quotation.created_by_name !== 'NUQLEA') {
		dataEntries.push({
			key: 'Pedido por',
			value: (
				<Typography>{`${quotation.created_by_name} | ${
					quotation.quotation_request_date && intl.formatDate(quotation.quotation_request_date, { timeZone: 'UTC' })
				}`}</Typography>
			),
		});
	} else {
		dataEntries.push({
			key: 'Pedido de cotización',
			value: (
				<EmptySupportedTypography>
					{quotation.quotation_request_date && intl.formatDate(quotation.quotation_request_date, { timeZone: 'UTC' })}
				</EmptySupportedTypography>
			),
		});
	}

	dataEntries.push({
		key: 'Productos',
		value: <EmptySupportedTypography>{quotation.products}</EmptySupportedTypography>,
	});

	dataEntries.push({
		key: 'Proyecto',
		value: <EmptySupportedTypography>{quotation.project}</EmptySupportedTypography>,
	});
	
	if (quotation.seller) {
		dataEntries.push({
			key: 'Vendedor',
			value: (
				<Seller
					name={quotation.seller.name}
					taxId={quotation.seller.cuit}
					image={quotation.seller.image}
					listId={listId}
					user={user}
					files={metadata?.seller_files?.filter((item: any) => item.visibility === 'all' || item.visibility === 'quotation')}
				/>
			),
		});
	}

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}
	};

	const getComponentsByActionStatusCode = (state?: EventStates) => {
		let elements;

		switch (state) {
			case EventStates.WAITING_FOR_USER_ACTION:
			case EventStates.MULTISELLER_CONFIRMED:
				elements = (
					<>
						<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
							<Grid item xs={12} md={6} sx={{ textAlign: 'end' }}>
								<StyledActionButton
									onClick={() => {
										if (state !== EventStates.MULTISELLER_CONFIRMED) {
											setConfirmationDialogOpen(true);
										} else {
											setMultiConfirmationDialogOpen(true);
										}
									}}
									variant="contained"
									color="success"
								>
									<ThumbUpAlt sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.buy_button' })}
								</StyledActionButton>
							</Grid>
							<Grid item xs={12} md={6} sx={{ textAlign: 'start' }}>
								<StyledActionButton onClick={() => setRejectionDialogOpen(true)} variant="outlined" color="error">
									<ThumbDownOffAlt sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.cancel_button' })}
								</StyledActionButton>
							</Grid>
						</Box>
					</>
				);
				break;

			case EventStates.OUTDATED_COMPARATIVE:
				// case EventStates.MULTISELLER_NOT_CONFIRMED:
				// case EventStates.UNISELLER_NOT_CONFIRMED:
				elements = (
					<>
							<Alert severity="error" sx={{ mt: 2, mr: 4, color: '#D32F2F', borderRadius: 3 }}>
								{intl.formatMessage({ id: 'list.details.estimation.is_outdated' })}
							</Alert>
						<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
							<Grid item xs={12} sx={{ textAlign: 'end' }}>
								<StyledActionButton
									onClick={() => setReQuotationDialogOpen(true)}
									variant="contained"
									color="success"
									sx={{ minWidth: '166px' }}
								>
									<Refresh sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.re_quote_button' })}
								</StyledActionButton>
							</Grid>
							<Grid item xs={12} sx={{ textAlign: 'start' }}>
								<StyledActionButton onClick={() => setRejectionDialogOpen(true)} variant="outlined" color="error">
									<ThumbDownOffAlt sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.cancel_button' })}
								</StyledActionButton>
							</Grid>
						</Box>
					</>
				);
				break;

			case EventStates.REUPLOAD_OC:
				elements = (
					<>
						{files?.nuqlea_estimate?.approved_by && (
								<StyledAlert2
									severity="info"
									sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
								>
									{intl.formatMessage(
										{ id: 'list.details.estimation.event_states.accepted' },
										{
											who: <><strong>{files?.nuqlea_estimate?.approved_by}</strong></> || ' ... ',
											when: files.nuqlea_estimate?.approved_at
												? intl.formatDate(files.nuqlea_estimate?.approved_at.split('T')[0], { timeZone: 'UTC' }) +
												  ' ' +
												  [
														files?.nuqlea_estimate?.approved_at.split('T')[1].split(':')[0],
														files?.nuqlea_estimate?.approved_at.split('T')[1].split(':')[1],
												  ].join(':') +
												  ' hs'
												: ' ... ',
										}
									)}
								</StyledAlert2>
						)}
						
							<StyledAlert2
								severity="info"
								sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
							>
								{intl.formatMessage(
									{ id: 'list.details.estimation.event_states.reupload_oc' },
									{
										commentsButton: (
											<Typography
												component="span"
												onClick={() => setOpenListCommentsDialog(true)}
												sx={{ cursor: 'pointer', color: '#2f5dff', fontSize: '.88em' }}
											>
												{intl.formatMessage({ id: 'list.details.list_comments.activator_label' })}
												{unreadMessages && unreadMessages > 0 ? ` (${unreadMessages})` : ''}
											</Typography>
										),
									}
								)}
							</StyledAlert2>
						<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
							<Grid item xs={12} md={7} sx={{ textAlign: 'end' }}>
								<Button
									onClick={() => setConfirmationDialogOpen(true)}
									variant="contained"
									color="success"
									sx={{ textTransform: 'none' }}
								>
									{intl.formatMessage({ id: 'list.details.estimation.new_oc_button' })}
								</Button>
							</Grid>
							<Grid item xs={12} md={5} sx={{ textAlign: 'start' }}>
								<StyledActionButton onClick={() => setRejectionDialogOpen(true)} variant="outlined" color="error">
									<ThumbDownOffAlt sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.cancel_button' })}
								</StyledActionButton>
							</Grid>
						</Box>
					</>
				);
				break;

			case EventStates.ACCEPTED:
			case EventStates.WAITING_FOR_OC:
			case EventStates.WAITING_FOR_OC_CONFIRMATION:
			case EventStates.WAITING_FOR_ACCEPTANCE:
			case EventStates.ACCEPTED_OC:
			case EventStates.REJECTED_OC:
				elements = (
					
						<StyledAlert2
							severity="info"
							sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
						>
							{files?.nuqlea_estimate?.approved_by
								? intl.formatMessage(
										{ id: 'list.details.estimation.event_states.accepted' },
										{
											who: <><strong>{files?.nuqlea_estimate?.approved_by}</strong></> || ' ... ',
											when: files.nuqlea_estimate?.approved_at
												? intl.formatDate(files.nuqlea_estimate?.approved_at.split('T')[0], { timeZone: 'UTC' }) +
												  ' ' +
												  [
														files?.nuqlea_estimate?.approved_at.split('T')[1].split(':')[0],
														files?.nuqlea_estimate?.approved_at.split('T')[1].split(':')[1],
												  ].join(':') +
												  ' hs'
												: ' ... ',
										}
								  )
								: intl.formatMessage({ id: 'list.details.estimation.event_states.accepted_by_nobody' })}
						</StyledAlert2>
					
				);
				break;

			case EventStates.ACCEPTED_REQUOTE:
			case EventStates.WAITING_FOR_REQUOTE:
			case EventStates.EXTENDED_TIME_QUOTATION:
				elements = (
						<StyledAlert
							severity="info"
							sx={{
								px: 2,
								color: '#222',
								borderRadius: 3,
								'& .MuiAlert-icon': { color: '#3B74FB', fontSize: '1.4em', marginRight: '5px' },
							}}
						>
							{intl.formatMessage(
								{ id: 'list.details.estimation.event_states.waiting_for' },
								{
									action: (
										<span style={{ color: '#23C323' }}>
											{intl.formatMessage({ id: 'list.details.estimation.event_states.action.requote' })}
										</span>
									),
								}
							)}
						</StyledAlert>
				);
				break;

			case EventStates.WAITING_FOR_REVISION_NEW_OC:
				elements = (
						<StyledAlert
							severity="info"
							sx={{
								px: 2,
								color: '#222',
								borderRadius: 3,
								'& .MuiAlert-icon': { color: '#3B74FB', fontSize: '1.4em', marginRight: '5px' },
							}}
						>
							{intl.formatMessage({ id: 'list.details.estimation.event_states.waiting_for_revision_new_oc' })}
						</StyledAlert>
				);
				break;

			case EventStates.WAITING_FOR_REJECTION:
				elements = (
						<StyledAlert
							severity="info"
							sx={{
								px: 2,
								color: '#222',
								borderRadius: 3,
								'& .MuiAlert-icon': { color: '#3B74FB', fontSize: '1.4em', marginRight: '5px' },
							}}
						>
							{intl.formatMessage(
								{ id: 'list.details.estimation.event_states.waiting_for' },
								{
									action: (
										<span style={{ color: '#D32F2F' }}>
											{intl.formatMessage({ id: 'list.details.estimation.event_states.action.reject' })}
										</span>
									),
								}
							)}
						</StyledAlert>
				);
				break;

			case EventStates.WAITING_FOR_COMPARISON:
			case EventStates.BEING_REVIEWED:
				elements = (
						<StyledAlert
							severity="info"
							sx={{
								px: 2,
								color: '#222',
								borderRadius: 3,
								'& .MuiAlert-icon': { color: '#3B74FB', fontSize: '1.4em', marginRight: '5px' },
							}}
						>
							{intl.formatMessage(
								{ id: 'list.details.estimation.event_states.waiting_for' },
								{
									action: (
										<span style={{ color: '#23C323' }}>
											{intl.formatMessage({ id: 'list.details.estimation.event_states.action.comparison' })}
										</span>
									),
								}
							)}
						</StyledAlert>
				);
				break;

			case EventStates.NEEDS_USER_REVISION:
				elements = (
					<>
							<StyledAlert2
								severity="info"
								sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
							>
								{intl.formatMessage(
									{ id: 'list.details.estimation.event_states.needs_user_revision' },
									{
										commentsButton: (
											<Typography
												component="span"
												onClick={() => setOpenListCommentsDialog(true)}
												sx={{ cursor: 'pointer', color: '#2f5dff', fontSize: '.88em' }}
											>
												{intl.formatMessage({ id: 'list.details.list_comments.activator_label' })}
												{unreadMessages && unreadMessages > 0 ? ` (${unreadMessages})` : ''}
											</Typography>
										),
									}
								)}
							</StyledAlert2>
						<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
							<Grid item xs={12} md={7} sx={{ textAlign: 'end' }}>
								<StyledActionButton
									onClick={() => setConfirmationOnlyDialogOpen(true)}
									variant="contained"
									color="success"
									sx={{ textTransform: 'none' }}
								>
									<ThumbUpAlt sx={{ marginRight: '5px' }} /> {intl.formatMessage({ id: 'common.accept' })}
								</StyledActionButton>
							</Grid>
							<Grid item xs={12} md={5} sx={{ textAlign: 'start' }}>
								<StyledActionButton onClick={() => setRejectionDialogOpen(true)} variant="outlined" color="error">
									<ThumbDownOffAlt sx={{ marginRight: '5px' }} />{' '}
									{intl.formatMessage({ id: 'list.details.estimation.cancel_button' })}
								</StyledActionButton>
							</Grid>
						</Box>
						{/* ACA VA LA MODAL DE CONFIRMAR DIRECTAMENTE - VER MOCKUP */}
					</>
				);
				break;

			case EventStates.REQUOTE_BY_OP:
				elements = (
						<StyledAlert2
							severity="info"
							sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
						>
							{intl.formatMessage({ id: 'list.details.estimation.event_states.requote_by_op' })}
						</StyledAlert2>
				);
				break;

			case EventStates.MULTISELLER_NOT_CONFIRMED:
			case EventStates.UNISELLER_NOT_CONFIRMED:
				elements = (
						<StyledAlert2
							severity="info"
							sx={{ px: 2, color: '#222', borderRadius: 3, '& .MuiAlert-icon': { display: 'none' } }}
						>
							{intl.formatMessage(
								{ id: 'list.details.estimation.event_states.multiseller_not_confirmed' },
								{
									commentsButton: (
										<Typography
											component="span"
											onClick={() => setOpenListCommentsDialog(true)}
											sx={{ cursor: 'pointer', color: '#2f5dff', fontSize: '.88em' }}
										>
											{intl.formatMessage({ id: 'list.details.list_comments.activator_label' })}
											{unreadMessages && unreadMessages > 0 ? ` (${unreadMessages})` : ''}
										</Typography>
									),
								}
							)}
						</StyledAlert2>
				);
				break;

			case EventStates.NO_ACTION_POSSIBLE:
			case EventStates.ACCEPTED_REJECTION:
			default:
				elements = <></>;
				break;
		}

		return elements;
	};

	const copyToClipboard = (text?: string) => {
		const el = document.createElement('textarea');
		el.value = text || '';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		toast.success(intl.formatMessage({ id: 'common.data_copied' }));
	}

	return (
		<PageContainer>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<StatusCard
						statuses={constructorStatuses.map((status) => ({
							label: status.id,
							warning: status.getWarning && status.getWarning(data),
							active: status.isActive(data),
							completed: status.isCompleted(data),
						}))}
						quotationId={metadata.quotation_id}
						infoMessage={
							!metadata.quotation_id ? (
								<Box sx={{ display: 'flex', alignItems: 'center'}}>
									<Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '280px' }}>
										<b>{intl.formatMessage({ id: 'list.details.data.no_quotation_id' })}</b> {listId}
									</Box>
									<IconButton onClick={() => copyToClipboard(listId)} sx={{ padding: 0, ml: '5px', '& svg': { fill: '#406AFF' } }}>
										<ContentCopy fontSize="small"></ContentCopy>
									</IconButton>
								</Box>
							) : undefined
						}
						rightSubtitle={
							<DeliveryAddress
								sx={{ justifyContent: 'end', mr: '40px' }}
								address={quotation?.address ?? ''}
								locality={quotation?.locality}
								user={user}
							></DeliveryAddress>
						}
						subtitleLines={1}
					/>
				</Grid>
				<Grid item xs={12} md={5}>
					<DataCard
						title={intl.formatMessage(
							{ id: 'list.details.data.title' },
							{
								quotationId:
									quotation?.status === QuoteStatus.EN_PROCESO_DE_COMPRA ||
									quotation?.status === QuoteStatus.EN_PROCESO_DE_ENTREGA ||
									quotation?.status === QuoteStatus.COMPRA_FINALIZADA ||
									quotation?.status === QuoteStatus.NO_CONCRETADA
										? metadata.whatsapp_list_id
											? metadata.whatsapp_list_id
													?.replace(/[A-Z]{1,1}$/, ` ${metadata.whatsapp_list_id.match(/[A-Z]{1,1}$/)}`)
													.trim()
											: ''
										: metadata?.quotation_id || '',
								orderId: '',
							}
						)}
						entries={dataEntries}
						actions={
							metadata?.can_only_be_canceled && !metadata?.pendings && quotation.status === 'En proceso de cotización'
								? [
										{
											displayName: intl.formatMessage({ id: 'list.details.cancel.action' }),
											hidden: true,
											onClick: () => setCancellationDialogOpen(true),
										},
								  ]
								: []
						}
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<Card
						title={
							intl.formatMessage({ id: 'list.details.estimation.title' }) +
							(files?.nuqlea_estimate?.comparative_version_number
								? ` - ${intl.formatMessage({ id: 'common.version' })} ${
										files?.nuqlea_estimate?.comparative_version_number
								  }`
								: ``)
						}
						actions={[
							{
								displayName: intl.formatMessage({ id: 'list.details.list_comments.activator_label' }),
								onClick: () => setOpenListCommentsDialog(true),
								variant: 'outlined',
								badge: unreadMessages && unreadMessages > 0 ? true : false,
							},
						]}
					>
							<Grid container spacing={5} sx={{ height: '100%', alignItems: 'center' }}>
								{!metadata?.has_actions &&
									!data.events &&
									!(files?.nuqlea_estimate?.id || files?.nuqlea_estimate?.gcs_file_path) && (
										<Grid item xs={12}>
											<Typography textAlign="center">
												{intl.formatMessage({ id: 'list.details.estimation.not_ready_yet' })}
											</Typography>
										</Grid>
									)}
								{(files?.nuqlea_estimate?.id || files?.nuqlea_estimate?.gcs_file_path) && (
									<>
										<Grid item xs={12} sm={6} textAlign={mobile ? 'center' : 'right'}>
											<File
												title={intl.formatMessage({ id: 'common.estimation' })}
												actions={[
													{
														title: intl.formatMessage({ id: 'common.view' }),
														icon: <VisibilityIcon />,
														loading: estimateDownloading,
														onClick: () => {
															setEstimateDownloading(true);
															if (files?.nuqlea_estimate?.id) {
																Promise.all([
																	client.getDriveFileName({ driveFileId: files?.nuqlea_estimate?.id, user }),
																	client.getDriveFile({
																		driveFileId: files?.nuqlea_estimate?.id,
																		fileId: files?.nuqlea_estimate?.id,
																		listId,
																		user,
																	}),
																])
																	.then(([nameData, fileData]) => {
																		setEstimateForcedState({
																			open: true,
																			nameData,
																			fileData,
																		});
																	})
																	.finally(() => setEstimateDownloading(false));
															}

															if (files?.nuqlea_estimate?.gcs_file_path) {
																Promise.all([
																	client.downloadGCSFile(
																		files?.nuqlea_estimate?.gcs_file_path,
																		'base64',
																		user.id_external,
																		user.token
																	),
																	client.downloadGCSBinaryFile(
																		files?.nuqlea_estimate?.gcs_file_path,
																		user.id_external,
																		user.token
																	),
																])
																	.then(([nameData, fileData]) => {
																		setEstimateForcedState({
																			open: true,
																			nameData,
																			fileData,
																		});
																	})
																	.finally(() => setEstimateDownloading(false));
															}
														}, // TODO Change this with download  files.nuqlea_estimate.id
													},
												]}
											/>
											<FileViewer
												driveFileId={files?.nuqlea_estimate?.id}
												fileId={files?.nuqlea_estimate?.id}
												listId={listId}
												user={user}
												forcedState={estimateForcedState}
												gcsFilePath={files?.nuqlea_estimate?.gcs_file_path}
											/>
										</Grid>
										<Grid item xs={12} sm={6} textAlign={mobile ? 'center' : 'start'}>
											<DataList
												entries={[
													{
														key: 'Fecha de carga',
														value: (
															<EmptySupportedTypography>
																{files.nuqlea_estimate.date_loaded &&
																	intl.formatDate(files.nuqlea_estimate.date_loaded, { timeZone: 'UTC' })}
															</EmptySupportedTypography>
														),
													},
													{
														key: 'Fecha de vencimiento',
														value: (
															<EmptySupportedTypography>
																{files.nuqlea_estimate.date_due &&
																	intl.formatDate(files.nuqlea_estimate.date_due, { timeZone: 'UTC' })}
															</EmptySupportedTypography>
														),
													},
												]}
											/>
										</Grid>
									</>
								)}

								{
									// Display Alerts & Actions based on event_state value
									<Grid item xs={12} textAlign={mobile ? 'center' : 'start'}>
										{quotation?.status === QuoteStatus.COTIZACION_RECIBIDA ||
											quotation?.status === QuoteStatus.EN_PROCESO_DE_COMPRA ||
											(quotation?.status === QuoteStatus.EN_PROCESO_DE_COTIZACION &&
												event_state === EventStates.REQUOTE_BY_OP) ? (
											getComponentsByActionStatusCode(event_state)
										) : (quotation?.status === QuoteStatus.EN_PROCESO_DE_COTIZACION &&
											event_state !== EventStates.REQUOTE_BY_OP) ||
											quotation?.status === QuoteStatus.NO_CONCRETADA ||
											quotation?.status === QuoteStatus.SIN_ESTADO ||
											metadata?.accepted === null ? (
											<></>
										) : (
											getComponentsByActionStatusCode(EventStates.ACCEPTED)
										)}
									</Grid> 
								}
							</Grid>
					</Card>
				</Grid>

				<Grid item xs={12}>
					<TableCardTreeView
						loading={!filesResponse}
						title={intl.formatMessage({ id: 'list.details.related_files.title' })}
						layoutColumns={12}
						columns={relatedFiles}
						relatedColumns={relatedFiles}
						rows={allDocuments?.map((document) => ({ ...document, user, list_id: listId }))}
						actions={[
							...(
								showButtonUploadPurchaseOrder
									? [
											{
												displayName: intl.formatMessage({ id: 'list.details.upload_file_purchase_order.action' }),
												icon: <Upload />,
												disabled: !data.metadata?.can_upload_oc,
												type: 'Button',
												variant: 'outlined' as 'outlined',
												onClick: () => setPurchaseOrderUploadDialogOpen(true),
											},
									  ]
									: []
							),
							{
								displayName: intl.formatMessage({ id: 'list.details.upload_file.action' }),
								icon: <Upload />,
								disabled: !data.metadata?.can_upload_oc && !data.metadata?.can_upload_cp,
								type: 'Button',
								onClick: () => setFileUploadDialogOpen(true),
							},
						]}
					></TableCardTreeView>
				</Grid>
				{STATUSES_WITH_DELIVERY_INFO.includes(status as QuoteStatus) && (
					<Grid item xs={12} ref={deliveriesCardRef}>
						<TableCardTreeView
							loading={!deliveriesResponse}
							title={intl.formatMessage({ id: 'list.details.related_deliveries.title' })}
							layoutColumns={12}
							columns={relatedDeliveries}
							relatedColumns={relatedDeliveryFiles}
							rows={allDeliveries?.map((delivery) => ({
								...delivery,
								list_id: metadata.list_id,
								user,
							}))}
							emptyMessage={intl.formatMessage({ id: 'list.details.related_deliveries.empty_text' })}
						></TableCardTreeView>
					</Grid>
				)}
			</Grid>
			<FileUploadDialog
				open={fileUploadDialogOpen}
				types={allowedFileTypes}
				documents={
					allDocuments?.filter(
						(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
					) || []
				}
				dataConstructor={data}
				user={user}
				onClose={(event, reason, newFile) => {
					setFileUploadDialogOpen(false);

					if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile) fetchListDocuments();
				}}
			/>
			<PurchaseOrderUploadDialog
				dataConstructor={data}
				listId={listId ?? ''}
				open={purchaseOrderUploadDialogOpen}
				onClose={(event, reason, newFile) => {
					setPurchaseOrderUploadDialogOpen(false);
				 	if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile) fetchListDocuments();
				}}
				seller={quotation.seller ?? {}}
				sellerFiles={metadata?.seller_files ?? []}
			/>
			<ReQuotationDialog
				open={reQuotationDialogOpen}
				quotationId={metadata.quotation_id}
				listId={metadata.list_id}
				environment={metadata.environment}
				outdated={metadata.is_outdated}
				user={user}
				onClose={(event, reason) => {
					setReQuotationDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) {
						setListDetailsResponse({
							...listDetailsResponse,
							data: {
								...data,
								event_state: EventStates.WAITING_FOR_REQUOTE,
							},
						});
						// fetchData();
					}
				}}
			/>
			<ConfirmationDialog
				open={confirmationDialogOpen}
				companies={companies}
				winnerQuotations={files?.nuqlea_estimate?.winner_quotations}
				quoteVersion={files?.nuqlea_estimate?.comparative_version_number ?? 1}
				quotationDriveFileId={files.nuqlea_estimate.id}
				gcsFilePath={files?.nuqlea_estimate?.gcs_file_path}
				listId={metadata.list_id}
				orderId={metadata.order_id}
				onClose={(event, reason) => {
					setConfirmationDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
				reloadCompanies={fetchCompanies}
			/>
			<ConfirmationOnlyDialog
				open={confirmationOnlyDialogOpen}
				winnerQuotations={files?.nuqlea_estimate?.winner_quotations}
				quoteVersion={files?.nuqlea_estimate?.comparative_version_number ?? 1}
				quotationDriveFileId={files.nuqlea_estimate.id}
				gcsFilePath={files?.nuqlea_estimate?.gcs_file_path}
				listId={metadata.list_id}
				orderId={metadata.order_id}
				user={user}
				onClose={(event, reason) => {
					setConfirmationOnlyDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
			<ConfirmationMultiDialog
				open={multiConfirmationDialogOpen}
				winnerQuotations={files?.nuqlea_estimate?.winner_quotations}
				quoteVersion={files?.nuqlea_estimate?.comparative_version_number ?? 1}
				quotationDriveFileId={files.nuqlea_estimate.id}
				gcsFilePath={files?.nuqlea_estimate?.gcs_file_path}
				listId={metadata.list_id}
				orderId={metadata.order_id}
				user={user}
				onClose={(event, reason) => {
					setMultiConfirmationDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
			<CancellationDialog
				open={cancellationDialogOpen}
				quotationId={metadata.quotation_id}
				listId={metadata.list_id}
				environment={metadata.environment}
				outdated={metadata.is_outdated}
				user={user}
				onClose={(event, reason) => {
					setCancellationDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) fetchData();
				}}
			/>
			<RejectionDialog
				open={rejectionDialogOpen}
				reasons={data.cancelation_reasons}
				quotationId={metadata.quotation_id}
				listId={metadata.list_id}
				environment={metadata.environment}
				outdated={metadata.is_outdated}
				user={user}
				onClose={(event, reason) => {
					setRejectionDialogOpen(false);
					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '')) {
						setListDetailsResponse({
							...listDetailsResponse,
							data: {
								...data,
								event_state: EventStates.WAITING_FOR_REJECTION,
							},
						});
					}
					// fetchData();
				}}
			/>
			<ListComments
				open={openListCommentsDialog}
				listId={listId}
				orderId={orderId}
				user={user}
				onClose={() => setOpenListCommentsDialog(false)}
				onReadComments={() => setUnreadMessages(undefined)}
			/>
		</PageContainer>
	);
};

export default Details;
