import React, { useEffect, useState } from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	// Accordion,
	AccordionSummary,
	AccordionDetails,
	styled as styledMui,
	Select,
	MenuItem,
	CircularProgress,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMobile } from '../../../../hooks/use-mobile';
import disableRootElementScrollState from '../../../../atoms/disable-root-element-scroll-state';
import userState from '../../../../atoms/user-state';
import client from '../../../../clients/client';
import { formatNumberLocale, formatNumberWithCurrency } from '../../../../utils/data-utils';
import { AgreementCondition, ShippingZone } from '../../buy-tokens/reducer';
import { AgreementProduct } from '../../../../types';
import { Link } from 'react-router-dom';

const TabsContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.5rem 0.2rem;
	margin-bottom: 1rem 0;
`;

const TabContainer = styled.div<{ active: boolean; isPlaceholderDiv?: boolean }>`
	display: flex;
	border-bottom: ${(props) => (props.active ? '2px solid #406AFF' : '1px solid #C6C6C666')};
	color: ${(props) => props.active && 'blue'};
	font-weight: ${(props) => (props.active ? '600' : '400')};
	flex-grow: ${(props) => (props.isPlaceholderDiv ? '1' : '0')};
`;

const TabDivider = styled.div<{ isLastDivider?: boolean }>`
	display: flex;
	border-bottom: 1px solid #c6c6c666;
	min-width: 2rem;
	flex-grow: ${(props) => (props.isLastDivider ? '1' : '0')};
`;

const Tab = styled.p<{ isPlaceholderP?: boolean }>`
	text-align: center;
	font-size: 16px;
	opacity: ${(props) => (props.isPlaceholderP ? '0' : '1')};
	cursor: ${(props) => (props.isPlaceholderP ? 'inherit' : 'pointer')};
	user-select: ${(props) => (props.isPlaceholderP ? 'none' : 'inherit')};
	width: ${(props) => (props.isPlaceholderP ? '100%' : 'auto')};
	font-family: 'Inter';
`;

const ColumnHeader = styled(TableCell)(({ theme }) => ({
	color: '#000000',
	fontWeight: 'bold',
	verticalAlign: 'middle',
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
	color: 'grey.600',
	fontWeight: 400,
	textAlign: 'center',
	verticalAlign: 'middle',
}));

const Accordion = styledMui((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		borderBottom: `1px solid ${theme.palette.divider}`,
	})
);

interface Props {
	open: boolean;
	onClose: (event?: object | null, reason?: string) => void;
	agreementId?: string;
}

const termsByAgreement = {
	'/assets/terms_tokens.md': ['d9ac3a27', 'd9ac3a278', '14'],
	'/acopio/hierro/terms.md': ['15', '9', 'acd6c2c4'],
} as any;

const TermsAndConditionsDialog = (props: Props) => {
	const { open, onClose, agreementId } = props;
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const user = useRecoilValue(userState);

	const intl = useIntl();
	const isMobile = useMobile();

	const [activeTab, setActiveTab] = useState(0);
	const [termsContent, setTermsContent] = useState('');
	const [agreementData, setAgreementData] = useState<any>({});
	const [loading, setLoading] = useState(true);

	const fetchAgreementData = async () => {
		setLoading(true);

		await client
			.getAgreementsConditionsAndEquivalences({ token: user.token, agreementId })
			.then((response) => {
				const financingMethods: AgreementCondition[] = [];
				const shippings: AgreementCondition[] = [];
				let withdrawal: AgreementCondition | null = null;
				// let benefit: AgreementCondition | null = null;
				response.agreementConditions.forEach((condition: AgreementCondition) => {
					if (condition.status === 'active') {
						if (condition.condition_type_id === '4') {
							financingMethods.push(condition);
						}
						if (condition.condition_type_id === '1') {
							shippings.push(condition);
						}
						if (condition.condition_type_id === '6') {
							withdrawal = condition;
						}
						// if (condition.condition_type_id === '2' || condition.condition_type_id === '3') {
						//   benefit = condition;
						// }
					}
				});

				const deliveryZones: ShippingZone[] = [];
				shippings.forEach((shipping) =>
					shipping.zones?.forEach((zone: ShippingZone) =>
						deliveryZones.push({ ...zone, agreement_condition_id: shipping.id })
					)
				);
				deliveryZones.sort((a: ShippingZone, b: ShippingZone) => {
					if (
						(a.zone_label < b.zone_label && a.zone_label.length < b.zone_label.length) ||
						(a.zone_label < b.zone_label && a.zone_label.length === b.zone_label.length)
					) {
						return -1;
					}
					if (
						(a.zone_label > b.zone_label && a.zone_label.length > b.zone_label.length) ||
						(a.zone_label > b.zone_label && a.zone_label.length === b.zone_label.length)
					) {
						return 1;
					}
					return 0;
				});
				if (withdrawal) {
					(withdrawal as AgreementCondition)?.zones?.forEach((zone: ShippingZone) => {
						deliveryZones.push({ ...zone, agreement_condition_id: withdrawal?.id as string });
					});
				}

				financingMethods.sort((a: any, b: any) => {
					if (
						(a.name < b.name && a.name.length < b.name.length) ||
						(a.name < b.name && a.name.length === b.name.length)
					) {
						return -1;
					}
					if (
						(a.name > b.name && a.name.length > b.name.length) ||
						(a.name > b.name && a.name.length === b.name.length)
					) {
						return 1;
					}
					return 0;
				});

				setAgreementData({
					financingMethods,
					shippings,
					withdrawal,
					deliveryZones,
					products: response.agreement_products,
					unity: response.agreement.unity,
					labelUnitsInPresentation: response.agreement.label_units_in_presentation,
					buyAndExchange: {
						minPurchase: response.agreement.min_purchase,
						minRedeem: response.agreement.min_redeem,
						minTimeToShip: response.agreement.min_time_to_ship,
					},
					price: response.agreementInfo?.price ?? 0,
				});
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		(async () => {
			const termsUrl = Object.keys(termsByAgreement)
				.map((key) => (termsByAgreement[key].includes(agreementId) ? key : null))
				.filter((el) => el)[0];

			if (!termsUrl) {
				return;
			}

			await axios.get(`${window.location.origin}${termsUrl}`).then((result) => {
				setTermsContent(result.data);
			});
		})();

		if (agreementId) {
			fetchAgreementData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agreementId]);

	useEffect(() => {
		setDisableRootElementScroll(open);
	}, [open, setDisableRootElementScroll]);

	const renderContent = () => {
		switch (activeTab) {
			case 0:
				return (
					<>
						<Accordion className="rounded-none">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								className="font-bold"
								aria-controls="pricing-content"
								id="pricing-header"
							>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.pricing_section.title' })}
							</AccordionSummary>
							<AccordionDetails>
								<Box className="flex justify-between items-center">
									<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
										{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.pricing_section.left_content' })}
										{['15', '9', 'acd6c2c4'].includes(agreementId ?? '') && (
											<Box component={'span'} fontWeight={300}>
												{intl.formatMessage(
													{ id: 'terms_and_conditions_dialog.general_tab.pricing_section.left_content_audiences_link' },
													{
														link: (
															<Link to="/audiences" className="underline text-blue-500">
																{intl.formatMessage({
																	id: 'terms_and_conditions_dialog.general_tab.pricing_section.left_content_audiences_link_text',
																})}
															</Link>
														),
													}
												)}
											</Box>
										)}
									</Typography>
									<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
										{intl.formatMessage(
											{ id: 'terms_and_conditions_dialog.general_tab.pricing_section.right_content' },
											{ tokenValue: formatNumberWithCurrency(agreementData.price) }
										)}
									</Typography>
								</Box>
							</AccordionDetails>
						</Accordion>
						<Accordion className="rounded-none">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								className="font-bold"
								aria-controls="financing-content"
								id="financing-header"
							>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.financing_section.title' })}
							</AccordionSummary>
							<AccordionDetails className="py-0 -mt-3">
								{agreementData.financingMethods?.map((financing: AgreementCondition, index: number) => (
									<Box
										key={`termsAndConditions-financeOption${financing.id}`}
										className="flex justify-between items-center py-3"
										sx={{
											borderBottom: index !== agreementData.financingMethods.length - 1 ? '1px solid #C6C6C6' : 'unset',
										}}
									>
										<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
											{financing.name}
										</Typography>
										{!financing.value ? (
											<Typography color={'#47D27F'} fontWeight={500} sx={{ fontSize: '13px !important' }}>
												{intl.formatMessage({
													id: 'terms_and_conditions_dialog.general_tab.financing_section.no_cost',
												})}
											</Typography>
										) : financing.value_type === 'percentage' ? (
											<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
												{intl.formatMessage(
													{ id: 'terms_and_conditions_dialog.general_tab.financing_section.cost_percentage_value' },
													{ value: formatNumberLocale(financing.value) }
												)}
											</Typography>
										) : (
											<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
												{intl.formatMessage(
													{ id: 'terms_and_conditions_dialog.general_tab.financing_section.cost_fixed_value' },
													{ value: formatNumberWithCurrency(financing.value) }
												)}
											</Typography>
										)}
									</Box>
								))}
							</AccordionDetails>
						</Accordion>
						<Accordion className="rounded-none">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								className="font-bold"
								aria-controls="delivery-content"
								id="delivery-header"
							>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.delivery_section.title' })}
							</AccordionSummary>
							<AccordionDetails>
								{agreementData.deliveryZones?.map((zone: ShippingZone, index: number) => {
									const condition =
										agreementData.shippings.find(
											(shipping: AgreementCondition) => shipping.id === zone.agreement_condition_id
										) ?? agreementData.withdrawal;
									return (
										<Box
											key={`termsAndConditions-deliveryZone${zone.zone_id}-${index}`}
											className="flex justify-between items-center py-3"
											sx={{
												borderBottom: index !== agreementData.deliveryZones.length - 1 ? '1px solid #C6C6C6' : 'unset',
											}}
										>
											{condition.condition_type_id === '6' ? (
												// Withdrawal
												<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
													{intl.formatMessage({
														id: 'terms_and_conditions_dialog.general_tab.delivery_section.withdrawal',
													})}
												</Typography>
											) : (
												// Shipping
												<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
													{zone.zone_label}
												</Typography>
											)}
											{condition.condition_type_id === '6' ? (
												// Withdrawal
												<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
													{zone.zone_label}
												</Typography>
											) : !condition.value ? (
												<Typography color={'#47D27F'} fontWeight={500} sx={{ fontSize: '13px !important' }}>
													{intl.formatMessage({
														id: 'terms_and_conditions_dialog.general_tab.delivery_section.no_cost',
													})}
												</Typography>
											) : condition.value_type === 'percentage' ? (
												<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
													{intl.formatMessage(
														{ id: 'terms_and_conditions_dialog.general_tab.financing_section.cost_percentage_value' },
														{ value: formatNumberLocale(condition.value) }
													)}
												</Typography>
											) : (
												<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
													{intl.formatMessage(
														{ id: 'terms_and_conditions_dialog.general_tab.financing_section.cost_fixed_value' },
														{ value: formatNumberWithCurrency(condition.value) }
													)}
												</Typography>
											)}
										</Box>
									);
								})}
							</AccordionDetails>
						</Accordion>
						<Accordion className="rounded-none">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								className="font-bold"
								aria-controls="buy-and-exchange-content"
								id="buy-and-exchange-header"
							>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.title' })}
							</AccordionSummary>
							<AccordionDetails>
								<Box className="flex justify-between items-center py-2" sx={{ borderBottom: '1px solid #C6C6C6' }}>
									<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
										{intl.formatMessage({
											id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.min_purchase',
										})}
									</Typography>
									<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
										{agreementData.buyAndExchange?.minPurchase > 1
											? intl.formatMessage(
													{ id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.tokens' },
													{ value: agreementData.buyAndExchange?.minPurchase }
											  )
											: intl.formatMessage({
													id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.token',
											  })}
									</Typography>
								</Box>
								<Box className="flex justify-between items-center py-2" sx={{ borderBottom: '1px solid #C6C6C6' }}>
									<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
										{intl.formatMessage({
											id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.min_redeem',
										})}
									</Typography>
									<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
										{agreementData.buyAndExchange?.minRedeem > 1
											? `${agreementData.buyAndExchange?.minRedeem} ${agreementData.unity}s`
											: `${agreementData.buyAndExchange?.minRedeem} ${agreementData.unity}`}
									</Typography>
								</Box>
								<Box className="flex justify-between items-center py-2">
									<Typography fontWeight={600} sx={{ fontSize: '13px !important' }} color={'gray'}>
										{intl.formatMessage({
											id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.min_time_to_ship',
										})}
									</Typography>
									<Typography fontWeight={400} sx={{ fontSize: '13px !important' }} color={'GrayText'}>
										{agreementData.buyAndExchange?.minTimeToShip > 1
											? intl.formatMessage(
													{ id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.days' },
													{ value: agreementData.buyAndExchange?.minTimeToShip }
											  )
											: intl.formatMessage({
													id: 'terms_and_conditions_dialog.general_tab.buy_and_exchange_section.day',
											  })}
									</Typography>
								</Box>
							</AccordionDetails>
						</Accordion>
					</>
				);
			case 1:
				return (
					<Table>
						<TableHead>
							<TableRow>
								<ColumnHeader colSpan={1} className="w-[50%] text-sm">
									{intl.formatMessage({ id: 'terms_and_conditions_dialog.equivalences_tab.product_column' })}
								</ColumnHeader>
								<ColumnHeader colSpan={1} className="w-[25%] text-center text-sm">
									{agreementData.labelUnitsInPresentation}
								</ColumnHeader>
								<ColumnHeader colSpan={1} className="w-[25%] text-center text-sm">
									{intl.formatMessage({ id: 'terms_and_conditions_dialog.equivalences_tab.equivalence_column' })}
								</ColumnHeader>
							</TableRow>
						</TableHead>
						<TableBody>
							{agreementData.products?.map((product: AgreementProduct) => (
								<TableRow key={`termsAndConditions-productPrice${product.id}`}>
									<StyledCell className={`w-[50%] text-left text-sm ${product.equivalence === 1 ? 'font-bold' : ''}`}>
										{product.name}
									</StyledCell>
									<StyledCell className={`w-[25%] text-center text-sm ${product.equivalence === 1 ? 'font-bold' : ''}`}>
										{`${formatNumberLocale(product.units_in_presentation)}${
											product.unit_type ? ` ${product.unit_type}` : ''
										}`}
									</StyledCell>
									<StyledCell className={`w-[25%] text-center text-sm ${product.equivalence === 1 ? 'font-bold' : ''}`}>
										{formatNumberLocale(product.equivalence)}
									</StyledCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				);
			case 2:
				return (
					<Box
						className="px-12 content"
						sx={{ fontFamily: 'Inter' }}
						dangerouslySetInnerHTML={{ __html: termsContent }}
					/>
				);
			default:
				return <Box className="px-12 content" dangerouslySetInnerHTML={{ __html: termsContent }} />;
		}
	};

	return (
		<Dialog
			aria-labelledby="terms-and-conditions-dialog"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="lg"
			className="p-5"
			sx={{ '& .MuiPaper-root': { minHeight: 'calc(100% - 64px)', borderRadius: '6px' } }}
		>
			<DialogTitle className="text-2xl font-bold border-b-0">
				{intl.formatMessage({ id: 'terms_and_conditions_dialog.title' })}
				<IconButton aria-label="close" onClick={onClose} className="absolute right-5 top-5 text-gray-500">
					<CloseIcon />
				</IconButton>
				{isMobile ? (
					<Select
						disableUnderline
						labelId="select-tab-label"
						id="select-tab"
						value={activeTab}
						onChange={(event) => setActiveTab(event.target.value as number)}
						className="w-full mt-4"
						disabled={loading}
					>
						<MenuItem value={0}>{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.title' })}</MenuItem>
						<MenuItem value={1}>
							{intl.formatMessage({ id: 'terms_and_conditions_dialog.equivalences_tab.title' })}
						</MenuItem>
						<MenuItem value={2}>{intl.formatMessage({ id: 'terms_and_conditions_dialog.terms_tab.title' })}</MenuItem>
					</Select>
				) : (
					<TabsContainer>
						<TabContainer active={activeTab === 0}>
							<Tab onClick={() => !loading && setActiveTab(0)}>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.general_tab.title' })}
							</Tab>
						</TabContainer>
						<TabDivider>
							<Tab isPlaceholderP>{'|'}</Tab>
						</TabDivider>
						<TabContainer active={activeTab === 1}>
							<Tab onClick={() => !loading && setActiveTab(1)}>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.equivalences_tab.title' })}
							</Tab>
						</TabContainer>
						<TabDivider>
							<Tab isPlaceholderP>{'|'}</Tab>
						</TabDivider>
						<TabContainer active={activeTab === 2}>
							<Tab onClick={() => !loading && setActiveTab(2)}>
								{intl.formatMessage({ id: 'terms_and_conditions_dialog.terms_tab.title' })}
							</Tab>
						</TabContainer>
						<TabDivider isLastDivider>
							<Tab isPlaceholderP>{'|'}</Tab>
						</TabDivider>
					</TabsContainer>
				)}
			</DialogTitle>
			<DialogContent className={loading ? 'flex justify-center items-center' : ''}>
				{loading ? (
					<Box className="flex items-center justify-center h-full w-full">
						<CircularProgress color="inherit" />
					</Box>
				) : (
					renderContent()
				)}
			</DialogContent>
		</Dialog>
	);
};

export default TermsAndConditionsDialog;
