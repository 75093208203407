import { useEffect, useState } from 'react';
import { Alert, debounce, Container, Snackbar } from '@mui/material';
import { Role } from '../../types';
import { useIntl } from 'react-intl';
import client from '../../clients/client';
import ValidationDialog from './validation-dialog';
import {
	ConstructorCreation,
	ConstructorUserCreation,
	FormData,
	FormDataQueryParamConstructor,
	SellerCreation,
	SignUpStep,
} from './interfaces';
import { accountTypes } from './constants';
import SellerCreationComponent from './layout/seller-creation';
import ConstructorCreationComponent from './layout/constructor-creation';
import AccountTypeComponent from './layout/account-type';
import { hideEmail, validateForm } from './utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SignUp = () => {
	const navigate = useNavigate();
	const intl = useIntl();

	const [searchParams, setSearchParams] = useSearchParams();

	const queryParamStep = searchParams.get('step') as SignUpStep;
	const queryParamInitialData: FormDataQueryParamConstructor = searchParams.get('initialData')
		? JSON.parse(searchParams.get('initialData') as string)
		: null;

	const [validationOpen, setValidationOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [hasCuit, setHasCuit] = useState<boolean>(true);
	const [email, setEmail] = useState<string>('');
	const [formData, setFormData] = useState<FormData>({
		accountType: queryParamInitialData?.type_external || '',
		cuit: queryParamInitialData?.cuit || '',
		businessName: queryParamInitialData?.business_name_id || '',
		address: '',
		logoImage: [],
		cuitProof: [],
		fullName: '',
		phone: '+',
		password: '',
		mail: queryParamInitialData?.user_email || '',
		confirmedMail: '',
		workArea: '',
		passwordRepeat: '',
		category: '',
		role: '',
	});

	const [formErrors, setFormErrors] = useState<any>({
		accountType: '',
		cuit: '',
		businessName: '',
		logoImage: '',
		cuitProof: '',
		fullName: '',
		phone: '',
		password: '',
		mail: '',
		confirmedMail: '',
		workArea: '',
		category: '',
		passwordRepeat: '',
		role: '',
	});

	useEffect(() => {
		if (queryParamInitialData) {
			return;
		}
		navigate(`?step=${SignUpStep.ACCOUNT_CHECK}`, { replace: true });
	}, [navigate, queryParamInitialData]);

	const validationDialogContent = {
		constructor: {
			title: intl.formatMessage({ id: 'validation_dialog.constructor.title' }),
			body: intl.formatMessage(
				{
					id: hasCuit ? 'validation_dialog.constructor.cuit.message' : 'validation_dialog.constructor.no_cuit.message',
				},
				{ br: <br />, adminEmail: hideEmail(email) }
			),
			buttonText: intl.formatMessage({ id: 'common.end' }),
			onClose: () => {
				window.location.href = window.location.origin;
				setValidationOpen(false);
			},
		},
		seller: {
			title: intl.formatMessage({ id: 'validation_dialog.seller.title' }),
			body: intl.formatMessage({ id: 'validation_dialog.seller.message' }),
			buttonText: intl.formatMessage({ id: 'common.go_to_start' }),
			onClose: () => {
				window.location.href = window.location.origin;
				setValidationOpen(false);
			},
		},
	};

	const fetchCreateConstructorUser = debounce((fields, cuit) => {
		client
			.createConstructorUser({ fields, cuit })
			.then((data) => {
				setEmail(data.email);
				setLoading(false);
				setValidationOpen(true);
			})
			.catch((err) => {
				setError(
					err?.response?.data?.errors[0]?.msg ?? intl.formatMessage({ id: 'auth.signup.default_error_message' })
				);
				setLoading(false);
			});
	}, 250);

	const fetchConstructorCreation = debounce((fields, cuit, userData) => {
		fields.creator = userData.email;
		client
			.createConstructor({ fields, cuit })
			.then((data) => {
				fetchCreateConstructorUser(userData, cuit);
			})
			.catch((err) => {
				setError(
					err?.response?.data?.errors[0]?.msg ?? intl.formatMessage({ id: 'auth.signup.default_error_message' })
				);
				setLoading(false);
			});
	}, 250);

	const fetchHasCuit = debounce((cuit) => {
		client
			.existsConstructor({ cuit })
			.then((data) => {
				setSearchParams({ step: SignUpStep.CONSTRUCTOR_CREATION });
				setHasCuit(data.exists);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.data?.errors[0]?.msg ?? intl.formatMessage({ id: 'auth.signup.default_error_message' })
				);
				setLoading(false);
			});
	}, 250);

	const fetchSellerCreation = debounce((fields) => {
		client
			.createSeller({ fields })
			.then((data) => {
				console.log(data);
				setValidationOpen(true);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.data?.errors[0]?.msg ?? intl.formatMessage({ id: 'auth.signup.default_error_message' })
				);
				setLoading(false);
			});
	}, 250);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]:
				name === 'cuit'
					? value.replace(/\D/g, '')
					: name === 'phone'
						? value === ''
							? '+'
							: '+' + value.replace(/[^\d]/g, '')
						: name === 'mail' || name === 'confirmedMail'
							? value.trim().toLowerCase()
							: value,
		}));
	};

	const handleRestartFlow = (where: SignUpStep) => {
		setSearchParams({ step: where });
		navigate(`?step=${where}`, { replace: true });
	};

	// AGREGAR PASO X de N abajo del titulo de la pagina

	const handleChangeStep = () => {
		if (validateForm(queryParamStep, formData, setFormErrors)) {
			switch (queryParamStep) {
				case SignUpStep.ACCOUNT_CHECK:
					if (formData.accountType === accountTypes[4].value) {
						setSearchParams({ step: SignUpStep.SELLER_CREATION });
						navigate(`?step=${SignUpStep.SELLER_CREATION}`, { replace: true });
					} else {
						setLoading(true);
						fetchHasCuit(Number(formData.cuit));
					}
					break;
				case SignUpStep.CONSTRUCTOR_CREATION:
					setLoading(true);
					debounce(() => {
						client
							.existsConstructorUser({ email: formData.mail })
							.then((data) => {
								if (data.exists) {
									setLoading(false);
									setError(intl.formatMessage({ id: 'auth.signup.mail_exists' }));
								} else {
									const userData: ConstructorUserCreation = {
										name: formData.fullName,
										phone: Number(formData.phone),
										email: formData.mail,
										password: formData.password,
										area: formData.workArea,
										user_type: Role.CONSTRUCTOR,
									};

									if (hasCuit) {
										fetchCreateConstructorUser(userData, Number(formData.cuit));
									} else {
										let serviceData: ConstructorCreation = {
											business_name: formData.businessName,
											business_address: !!formData.address ? formData.address : null,
										};
										fetchConstructorCreation(serviceData, Number(formData.cuit), userData);
									}

									(globalThis as any).dataLayer?.push({
										"event": "ns_registro_exitoso",
										"value": {
											"user": {
												"name": userData?.name,
												"email": userData?.email,
												"phone": userData?.phone,
												"type_external": userData?.user_type,
												"area": userData?.area,

											},
											"company": {
												"name": serviceData?.business_name,
												"cuit": serviceData?.cuit,
											},
										}
									})
								}
							})
							.catch((err) => {
								setError(intl.formatMessage({ id: 'auth.signup.default_error_message' }));
								setLoading(false);
							});
					}, 250)();

					break;
				case SignUpStep.SELLER_CREATION:
					let serviceData: SellerCreation = {
						cuit: Number(formData.cuit),
						business_name: formData.businessName,
						category: formData.category,
						full_name: !!formData.fullName ? formData.fullName : null,
						phone: Number(formData.phone),
						mail: formData.mail,
						role: !!formData.role ? formData.role : null,
					};
					setLoading(true);
					fetchSellerCreation(serviceData);
					break;
			}
		}
	};

	const getComponentsByStep = (step?: SignUpStep) => {
		let component = <></>;

		switch (step) {
			case SignUpStep.ACCOUNT_CHECK:
				component = (
					<AccountTypeComponent
						formData={formData}
						formErrors={formErrors}
						handleInputChange={handleInputChange}
						loading={loading}
						handleChangeStep={handleChangeStep}
					/>
				);
				break;
			case SignUpStep.CONSTRUCTOR_CREATION:
				component = (
					<ConstructorCreationComponent
						formData={formData}
						formErrors={formErrors}
						handleInputChange={handleInputChange}
						loading={loading}
						handleRestartFlow={handleRestartFlow}
						handleChangeStep={handleChangeStep}
						hasCuit={hasCuit}
					/>
				);
				break;
			case SignUpStep.SELLER_CREATION:
				component = (
					<SellerCreationComponent
						formData={formData}
						formErrors={formErrors}
						handleInputChange={handleInputChange}
						loading={loading}
						handleRestartFlow={handleRestartFlow}
						handleChangeStep={handleChangeStep}
					/>
				);
				break;
			default:
				component = <></>;
				break;
		}

		return component;
	};

	return (
		<Container
			sx={{
				py: '40px',
			}}
			maxWidth="lg"
		>
			{getComponentsByStep(queryParamStep)}

			<ValidationDialog
				open={validationOpen}
				content={validationDialogContent[formData.accountType === 'seller' ? 'seller' : 'constructor']}
			/>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default SignUp;
