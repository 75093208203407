import { DataRow } from '../../table-card';
import { defaultFieldSort, defaultSort } from '../../../utils/data-utils';
import { IntlShape } from 'react-intl';
import { Typography, Box, styled, Avatar } from '@mui/material';
import { DeliveryStatus } from '../../../constants';
import { User } from '../../../types';
import FileViewer from '../../file-viewer';
import ToggleActionsButton from './deliveries/toggle-actions-button';

const DeliveredStatus = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	color: #3DBE00;
	background-color: #3DBE005C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 7rem;
	padding: 0.3rem;
`;

const PendingDeliveryStatus = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	color: #556AC3;
	background-color: #556AC35C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 7rem;
	padding: 0.3rem;
`;

const CanceledDeliveryStatus = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	color: #FF0005;
	background-color: #FF00055C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 7rem;
	padding: 0.3rem;
`;

const getDeliveryStatusCell = (row: DataRow, intl: IntlShape, user: User) => {
	if (row.status === 1) {
		return (
			<PendingDeliveryStatus>
				<Typography variant='body2' className='text-[#556AC3] font-semibold'>
					{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] })}
				</Typography>
			</PendingDeliveryStatus>
		);
	} else if (row.status === 2) {
		return (
			<DeliveredStatus>
				<Typography variant='body2' className='text-[#3DBE00] font-semibold'>
					{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] })}
				</Typography>
			</DeliveredStatus>
		);
	}

	return (
		<CanceledDeliveryStatus>
			<Typography variant='body2' className='text-[#FF0005] font-semibold'>
				{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] })}
			</Typography>
		</CanceledDeliveryStatus>
	);
};

const getDeliveryStatus = (row: DataRow) => (row.delivery_date ? 'Entregado' : 'Pendiente de entrega');

export const relatedFiles = [
	{
		displayName: 'Tipo de archivo',
		supportsMobile: true,
		sort: defaultFieldSort('label_tipo_archivo'),
		render: (row: DataRow) => (
			<Typography sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '2px' }}>
				{row.label_tipo_archivo}
				{!!row.status ? (
					<>
						<Typography>-</Typography>
						<Typography textTransform="capitalize" color="#47D27F" fontWeight={600}>
							{row.status}
						</Typography>
					</>
				) : <></>}
			</Typography>
		),
	},
	{
		displayName: 'Fecha',
		sort: defaultFieldSort('created_at'),
		render: (row: DataRow, intl: IntlShape) => row.created_at && intl.formatDate(row.created_at, { timeZone: 'UTC' }),
	},
	{
		displayName: 'Archivo',
		headerStyle: { width: '90px' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User, listId?: string) =>
			(row.file || row.gcs_file_path) ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
					<FileViewer
						driveFileId={row.file}
						fileId={row.label_tipo_archivo}
						listId={row.list_id ?? listId}
						user={row.user ?? user}
						buttonType="icon"
						gcsFilePath={row.gcs_file_path}
					/>
					{/* <FileDownloadButton
						driveFileId={row.drive_file_id}
						fileId={row.label_file_type}
						listId={row.list_id ?? listId}
						user={row.user ?? user}
					/> */}
				</Box>
			) : (
				intl.formatMessage({ id: 'common.not_available' })
			),
	},
];

export const relatedProducts = [
	{
		displayName: 'Descripción',
		supportsMobile: true,
		headerStyle: { width: { xs: '33%', md: '40%', xl: '50%' } },
		sort: defaultFieldSort('product_name'),
		render: (row: DataRow) => <Typography>{row.product_name}</Typography>,
	},
	{
		displayName: 'Sin entregar',
		headerStyle: { width: { xs: '33%', md: '30%', xl: '25%' }, textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape) => (
			<Box className='text-center flex items-center justify-center'>
				<Typography sx={{ fontSize: '1.1em', fontWeight: 900 }} className='mr-1'>{intl.formatNumber(row.unassigned + row.pending, { style: 'decimal' })}</Typography>
				<Typography sx={{ fontSize: '1em' }}>{row.unity + (row.quantity > 1 ? 's' : '')}</Typography>
			</Box>
		),
	},
	{
		displayName: 'Entregados',
		headerStyle: { width: { xs: '33%', md: '30%', xl: '25%' }, textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape) => (
			<Box className='text-center flex items-center justify-center'>
				<Typography sx={{ fontSize: '1.1em', fontWeight: 900 }} className='mr-1'>{intl.formatNumber(row.delivered, { style: 'decimal' })}</Typography>
				<Typography sx={{ fontSize: '1em' }}>{row.unity + (row.quantity > 1 ? 's' : '')}</Typography>
			</Box>
		),
	},
];

export const getSellerRelatedProductsColumns = (intl: IntlShape) => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.description' }),
		supportsMobile: true,
		headerStyle: { width: { xs: '33%', md: '40%', xl: '50%' } },
		sort: defaultFieldSort('product_name'),
		render: (row: DataRow) => <Typography variant='body2'>{row.product_name}</Typography>,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.requested' }),
		headerStyle: { width: { xs: '33%', md: '30%', xl: '25%' }, textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape) => (
			<Box className='text-center flex items-center justify-center'>
				<Typography variant='body2' className='mr-1 font-extrabold'>{intl.formatNumber(row.quantity, { style: 'decimal' })}</Typography>
				<Typography variant='body2'>{intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.unity.' + row.unity })}</Typography>
			</Box>
		),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.not_delivered' }),
		headerStyle: { width: { xs: '33%', md: '30%', xl: '25%' }, textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape) => (
			<Box className='text-center flex items-center justify-center'>
				<Typography variant='body2' className='mr-1 font-extrabold'>{intl.formatNumber(row.unassigned + row.pending, { style: 'decimal' })}</Typography>
				<Typography variant='body2'>{intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.unity.' + row.unity })}</Typography>
			</Box>
		),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.delivered' }),
		headerStyle: { width: { xs: '33%', md: '30%', xl: '25%' }, textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape) => (
			<Box className='text-center flex items-center justify-center'>
				<Typography variant='body2' className='mr-1 font-extrabold'>{intl.formatNumber(row.delivered, { style: 'decimal' })}</Typography>
				<Typography variant='body2'>{intl.formatMessage({ id: 'mytokens.redeem_view.seller.products.unity.' + row.unity })}</Typography>
			</Box>
		),
	},
];

export const relatedDeliveries = [
	{
		displayName: 'Tipo de entrega',
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User) => (
			<Box sx={{ py: row.label_tipo_entrega === 'Sin definir' ? 1 : 'inherit' }}>{row.label_tipo_entrega ?? '-'}</Box>
		),
	},
	{
		displayName: 'Estado',
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => defaultSort(getDeliveryStatus(a), getDeliveryStatus(b)),
		render: (row: DataRow, intl: IntlShape, user?: User) => getDeliveryStatusCell(row, intl, row.user ?? user),
	},
	{
		displayName: 'Cantidad',
		render: (row: DataRow, intl: IntlShape) => {
			const totalQuantity = row.products.reduce((acc: number, product: any) => acc + product.quantity, 0);
			return <Box>{`${intl.formatNumber(totalQuantity, { style: 'decimal' })} ${row.unity}${totalQuantity > 1 ? 's' : ''}`}</Box>
		},
	},
	{
		displayName: 'Entrega pactada',
		sort: defaultFieldSort('fecha_pactada'),
		render: (row: DataRow, intl: IntlShape) =>
			row.fecha_pactada && intl.formatDate(row.fecha_pactada, { timeZone: 'UTC' }),
	},
	{
		displayName: 'Entrega realizada',
		sort: defaultFieldSort('fecha_entrega'),
		render: (row: DataRow, intl: IntlShape) =>
			row.fecha_entrega && intl.formatDate(row.fecha_entrega, { timeZone: 'UTC' }),
	},
	{
		displayName: '',
		headerStyle: { width: '50px' },
		supportsMobile: true,
		render: (row: DataRow) => (
			<Box className={'flex justify-center'}>
				<ToggleActionsButton delivery={row} />
			</Box>
		),
	},
];

export const relatedDeliveryFiles = [
	{
		displayName: 'ID',
		headerStyle: { width: 'auto' },
		supportsMobile: true,
		render: (row: DataRow) => (row.document_id ? 'Remito #' + row.document_id : 'Remito'),
	},
	{
		headerStyle: { width: '90px' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User, listId?: string) =>
			row.document_file_id ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
					<FileViewer
						driveFileId={row.document_file_id}
						fileId={'Remito'}
						listId={row.list_id ?? listId}
						user={row.user ?? user}
						buttonType="icon"
					/>
					{/* <FileDownloadButton
					driveFileId={row.document_file_id}
					fileId={'Remito'}
					listId={row.list_id ?? listId}
					user={row.user ?? user}
				/> */}
				</Box>
			) : (
				intl.formatMessage({ id: 'common.not_available' })
			),
	},
];
