import { Box, Button, CssBaselineProps, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { ConstructionAddress, Location, StackedData, User } from '../../../types';
import { construct, purchaseBehavior } from './main-settings';
import { useMobile } from '../../../hooks/use-mobile';
import { constants } from 'buffer';
import addressHelper from '../../../helpers/addressHelper';
import LocationPicker from '../../location-picker';
import Map from '../../map';
import MapMarker from '../../map-marker';
import marketIcon from '../../../assets/nuqlea-map-marker.png';
import { blue } from '@mui/material/colors';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import EditButton from '../../nuqlea-components/edit-button';

export interface AddressDetailsProps {
	switchEditMode: (isEditionMode: boolean) => void;
	handleDelete: (address: ConstructionAddress) => void;
	constAddress: ConstructionAddress;
	showMap?: boolean;
	location?: Location;
	readOnly?: boolean;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({
	switchEditMode,
	constAddress,
	handleDelete,
	showMap,
	location,
	readOnly,
}) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const formatContacts = (): string => {
		let contactsString = '';
		constAddress.contacts.forEach((con) => {
			contactsString = contactsString + con.name + '\n' + con.phone + '\n\n';
		});
		return contactsString.slice(0, -1);
	};

	const data: StackedData[] = [
		{
			title: intl.formatMessage({ id: 'common.address' }),
			description: addressHelper.formatAddress({
				address: constAddress.address,
				city: constAddress.city,
				province: constAddress.province,
			}),
			extraFullRow: location?.coordinates ? (
				<Map
					mini={true}
					center={location.coordinates}
					zoom={15}
					height="200px"
					gestureHandling="none"
					zoomControl={false}
					fullscreenControl={false}
					mapTypeControl={false}
					streetViewControl={false}
					onClick={() => window.open(constAddress.placeUrl, '_blank')}
				>
					<MapMarker
						position={location.coordinates}
						icon={{
							url: marketIcon,
							scaledSize: new google.maps.Size(50, 58),
						}}
					/>
				</Map>
			) : undefined,
			extraFullRowTooltip: 'Ver en Google Maps',
		},
		{
			title: intl.formatMessage({ id: 'settings.company.addresses_tab.availability_input' }),
			description: constAddress.availability,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.addresses_tab.contact_subtitle' }),
			description: formatContacts(),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.addresses_tab.gathering_input' }),
			description: constAddress.gathering
				? intl.formatMessage({ id: 'common.yes' })
				: intl.formatMessage({ id: 'common.no' }),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.addresses_tab.comments_input' }),
			description: constAddress.comments,
		},
	];

	return readOnly ? (
		<Box className="pt-4">
			<StackDataDisplay data={data}></StackDataDisplay>
		</Box>
	) : (
		<GenericTabFrame
			buttons={[
				<EditButton onClick={() => switchEditMode(true)}>{intl.formatMessage({ id: 'common.edit' })}</EditButton>,
				<Button onClick={() => handleDelete(constAddress)} sx={{ ml: '20px' }}>
					{intl.formatMessage({ id: 'settings.company.addresses_tab.remove_address' })}
				</Button>,
			]}
			variant="card"
		>
			<StackDataDisplay data={data}></StackDataDisplay>
		</GenericTabFrame>
	);
};

export default AddressDetails;
